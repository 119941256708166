<template>
  <article-page :menu="menu">
    <div class="training-content" id="section-template-saving">
      <div class="_subtitle">
        Сохранение шаблонов
      </div>
      <span>
        Пользователи могут сохранить результаты поиска в шаблон. Новые закупки, удовлетворяющие сохраненным фильтрам,
        будут автоматически подгружаться несколько раз в день. Для сохранения расширенных фильтров, нажмите кнопку "Сохранить шаблон".
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
      <span>
        Для сохранения шаблона необходимо ввести название. Дополнительно можно выбрать группу шаблонов, назначить ответственного
        и выбрать время для отправки рассылки с новыми тендерами на почту ответственного лица.
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" @click="openViewer(images, 1)"/>
    </div>
    <div class="training-content" id="section-receive-notifications">
      <div class="_subtitle">
        Подписка на email рассылку, уведомляющую о новых закупках
      </div>
      <span>
        При создании шаблона или при редактировании его данных, Вы можете оформить подписку на него.
        Системой предусмотрено до 5 уведомлений в сутки, в выбранное время по московскому часовому поясу.
        Это означает, что при наличии новых закупок, к указанному времени будет автоматически генерироваться Excel файл с найденными закупками
        и отправляться ответственному лицу по электронной почте, указанной в настройках.
      </span>
      <span>
        По умолчанию уведомления отправляются на почту, указанную при регистрации.
        При необходимости, можно указать почтовый адрес для уведомлений, отличный от почты, указанной при регистрации.
        Для этого нужно перейти в раздел
        <accessed-link :name="Tabs.Settings.Notifications" target="_blank"> Уведомления </accessed-link>
        и вписать в разделе <b>"Рассылки по шаблонам"</b> в поле <b>"Email для уведомлений"</b> адрес получателя.
      </span>
      <alert-message full-info>
        <template #content>
          Обратите внимание, рассылка по шаблону идет ответственному лицу. Если на шаблон никто не назначен, то письмо отправлено не будет.
        </template>
      </alert-message>
      <article-image :src="images[2].src" :alt="images[2].alt" @click="openViewer(images, 2)"/>
    </div>
    <div class="training-content" id="section-template-search">
      <div class="_subtitle">
        Поиск по шаблонам
      </div>
      <span>
        Сохраненные шаблоны и созданные группы шаблонов можно выбрать в селекторе шаблонов.
      </span>
      <span>
        <b>"Все шаблоны"</b> и <b>"Закупки без шаблона"</b> - служебные элементы. "Все шаблоны" выдадут все закупки, удовлетворяющие сохраненным шаблонам.
        В "Закупках без шаблона" (1) хранятся закупки, с которыми взаимодействовал пользователь (переводил статусы, ответственных, писал комментарии), но при этом результаты поиска не были сохранены в шаблон.
      </span>
      <span>
        На приведенной ниже картинке под цифрой 2 показан пример сохраненной группы шаблонов с двумя шаблонами внутри.
        Справа под цифрой 3 показано количество непросмотренных закупок в каждом из шаблонов. Это новые закупки, которые не видел пользователь (по умолчанию закупка отмечается
        просмотренной, если она находится в зоне видимости на экране более 2 секунд. Это можно настроить в
        <accessed-link :name="Tabs.Settings.InterfaceSettings">настройках функциональности</accessed-link> - <router-link class="link" :to="{ name: Tabs.Training.WatchedLots }" target="_blank">читать подробнее</router-link>).
      </span>
      <article-image :src="images[3].src" :alt="images[3].alt" @click="openViewer(images, 3)"/>
      <span>
        Для показа результатов поиска по сохраненным шаблонам, Вам необходимо выбрать один или несколько нужных шаблонов в селекторе шаблонов и нажать на кнопку <b>Применить</b>.
      </span>
      <span>
        При выборе <b>одного шаблона</b>, в селекторе будет выведено его название, а рядом - четыре кнопки действий:
      </span>
      <ol>
        <li>Редактирование расширенных фильтров;</li>
        <li>Редактирование данных шаблона (название/группа/ответственный/расписание email уведомлений);</li>
        <li>Копирование шаблона;</li>
        <li>Удаление шаблона;</li>
      </ol>
      <article-image :src="images[4].src" :alt="images[4].alt" @click="openViewer(images, 4)"/>
      <span>
        При выборе <b>нескольких шаблонов</b>, будут доступны 4 кнопки действий:
      </span>
      <ol>
        <li>Назначение/снятие группы выбранным шаблонам;</li>
        <li>Назначение/снятие ответственного выбранным шаблонам;</li>
        <li>Редактирование времени уведомлений;</li>
        <li>Удаление выбранных шаблонов;</li>
      </ol>
      <article-image :src="images[5].src" :alt="images[5].alt" @click="openViewer(images, 5)"/>
    </div>
    <div class="training-content" id="section-edit-template">
      <div class="_subtitle">
        Редактирование фильтров сохраненного шаблона
      </div>
      <span>
        Для редактирование доступны все расширенные фильтры.
        Кроме сохранения изменений (1), Вы также можете сохранить фильтры <b>как новый шаблон</b> (2) или <b>выйти без сохранения</b> (3).
        При необходимости проверить поисковую выдачу без сохранения шаблона, вы можете воспользоваться кнопкой <b>"поиск без сохранения"</b> (4).
      </span>
      <article-image :src="images[6].src" :alt="images[6].alt" @click="openViewer(images, 6)"/>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useImageViewer } from "@/use/other/useImageViewer";
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";
import { Tabs } from "@/router/tabs";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";

export default defineComponent({
  name: "TemplatesArticle",
  components: {
    AlertMessage,
    AccessedLink,
    ArticleImage,
    ArticlePage,
  },
  setup() {

    const menu = [
      { id: 'section-template-saving', title: 'Сохранение шаблонов' },
      { id: 'section-receive-notifications', title: 'Подписка на новые закупки' },
      { id: 'section-template-search', title: 'Поиск по сохраненным шаблонам' },
      { id: 'section-edit-template', title: 'Редактирование сохраненного шаблона' },
    ]

    const images = [
      { src: 'base/save-new-template.png', alt: 'Сохранение данных для поиска в шаблон' },
      { src: 'base/save-new-template-dialog.png', alt: 'Создание нового шаблона' },
      { src: 'base/mailing-schedule.png', alt: 'Настройка уведомлений' },
      { src: 'base/template-selector.png', alt: 'Селектор шаблонов' },
      { src: 'base/template-actions.png', alt: 'Действия над одним сохраненным шаблоном' },
      { src: 'base/few-template-actions.png', alt: 'Действия над несколькими сохраненными шаблонами' },
      { src: 'base/template-edit.png', alt: 'Редактирование шаблона' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      openViewer,
      Tabs,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}
</style>
